.menu {
  display: flex;
  justify-content: space-between;
}

.container {
  margin: auto;
  margin-top: 80px;
  width: 80%;
}

.hotel-content {
  margin: 0;
  padding: 0;
  overflow: auto;
  height: 82vh;
}

svg {
  font-size: x-large;
}

.home {
  color: #fff;
  text-align: center;
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 420px;
  overflow: auto;
  left: 50%;
  width: 80%;
  height: 70vh;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

@media (max-width: 991px) {
  .ubication {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1500px) {
  .home {
    top: 495px;
  }
}
